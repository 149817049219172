import "./MinistryCount.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CardHeader,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { MinistryDataSetCount } from "./types";

export const MinistryCount = ({
  ministryOnlyCount,
  totalCountWithSubordinates,
  subordinates,
  ministryName,
  position,
}: MinistryDataSetCount & { position: number }) => {
  return (
    <Accordion
      sx={{
        width: 345,
        minHeight: 160,
        margin: "15px!important;",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ margin: "12px 0!important;", minHeight: "0!important;" }}
      >
        <CardHeader
          sx={{ padding: 0 }}
          avatar={
            <Avatar
              sx={{ bgcolor: "#B30000", fontWeight: 500, fontSize: "22px" }}
              title={"Position"}
            >
              {position}
            </Avatar>
          }
          title={ministryName}
          subheader={`${totalCountWithSubordinates} veröffentlichte Datensätze`}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          overflow: "auto",
          maxHeight: 200,
          scrollbarColor: "#6f7f95 #fff",
          scrollbarWidth: "thin",
          padding: 0,
        }}
      >
        <List dense={true} sx={{ paddingTop: 0 }}>
          <ListItem>
            <ListItemText
              primary="Ministerium"
              secondary={`${ministryOnlyCount} Datensätze`}
            />
          </ListItem>
          {subordinates.map(({ subordinateName, count }) => (
            <ListItem key={subordinateName}>
              <ListItemText
                primary={subordinateName}
                secondary={`${count} Datensätze`}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
