import React, { useEffect, useState } from "react";
import "./App.css";
import { MinistryCount } from "./MinistryCount";
import { GovdataDashboardResponseDto } from "./types";

function App() {
  const [dashboardData, setDashboardData] = useState<
    GovdataDashboardResponseDto | undefined
  >();

  useEffect(() => {
    fetch("/api/govdata-dashboard")
      .then((res) => res.json())
      .then((data) => setDashboardData(data));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>GovData Dashboard</h2>
      </header>
      <main className="main">
        {dashboardData &&
          dashboardData.datasetCountPerMinistry.map(
            (
              {
                ministryOnlyCount,
                totalCountWithSubordinates,
                subordinates,
                ministryName,
              },
              i,
            ) => (
              <MinistryCount
                key={ministryName}
                position={i + 1}
                ministryName={ministryName}
                ministryOnlyCount={ministryOnlyCount}
                totalCountWithSubordinates={totalCountWithSubordinates}
                subordinates={subordinates}
              />
            ),
          )}
      </main>
    </div>
  );
}

export default App;
